@import "~nvent-web/style/variables";

.wrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  background: $white;
}

.content {
  padding: 16px 30px;
  color: $charcoal-grey;
}

.info {
  flex: 1;
}

.header {
  display: flex;
}

.title {
  color: $cerulean-blue;
  font-weight: 500;
  text-align: unset;
  margin: 0;
  font-size: 21px;
}

.details {
  font-size: 0.85rem;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 8px 16px;
  font-weight: 500;
}

.referenceNumber {
  font-weight: 700;
}

.date {
  font-weight: 400;
  color: $roman-silver;
}

.actionButtonsWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.titleWrapper {
  display: flex;
  gap: 5px;
  align-items: center;
}

.editButton {
  border: 0;
  border-radius: 4px;
  background: transparent;

  &:hover {
    background: $pale-grey;
  }
}

.addButton {
  appearance: none;
  background: none;
  border: none;
  padding: 0 4px;
  border-radius: $border-radius;

  &:hover {
    background-color: $pale-grey;
  }
}

.actionButton {
  color: $cerulean-blue;
  cursor: pointer;
  border: none;
  background-color: transparent;
  text-align: center;
  padding: 15px 25px;
  font-weight: 600;

  &:first-of-type {
    margin-left: 0;
  }
}
