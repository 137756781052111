@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.wrapper {
  composes: container-fluid-width from global;
  background-color: $white;
  margin-bottom: 16px;
}

.inner {
}

.roomListHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 16px 0 10px;

  p {
    color: $steel-grey;
    margin: 0;
  }

  @include media-breakpoint-up(lg) {
    margin: 20px 0;
  }
}

.title {
  font-family: $font-family-base;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: $charcoal-grey;
}

.notice {
  font-family: $font-family-base;
  font-size: 16px;
  letter-spacing: -0.3px;
  color: $steel-grey;
}

.actions {
  @include flexbox(flex-end, center);
}

.okButton {
  width: 140px;
}

.commissioning {
  text-align: center;
  margin-bottom: 16px;
}

.ready {
  font-size: 16px;
  letter-spacing: -0.3px;
  color: $light-blue-grey;
}

.button {
  width: 100%;
}

.addButton {
  @include media-breakpoint-up(lg) {
    min-width: 140px;
  }
}

.listWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
  padding: 0;
}
