@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.row {
  margin-bottom: calc($grid-gutter-width / 4);

  @include flexbox(space-between, center);
}

.floorFinishRow {
  composes: row from global;

  @include media-breakpoint-up(lg) {
    margin-bottom: 16px;
    justify-content: flex-start;
  }
}

.progressRow {
  @include media-breakpoint-up(lg) {
    margin-bottom: 16px;
  }
}

.rowStart {
  composes: row;

  @include flexbox(flex-start, baseline);
}

.value {
  font-family: $font-family-base;
  font-size: 14px;
  color: $charcoal-grey;
  margin: 0;
}

.wrapper {
  composes: container-fluid-width from global;
  background-color: $white;
  overflow: hidden;
  padding-top: 15px;
}

.inner {
  composes: container-fluid from global;
}

.name {
  font-family: $font-family-base;
  font-size: 20px;
  font-weight: 500;
  color: $charcoal-grey;
  margin: 0 14px 0 0;
}

.editButtonMobileWrapper {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.editButtonDesktopWrapper {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
    padding-right: 20px;
  }
}

.area {
  composes: value;
}

.areaMobileWrapper {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.areaDesktopWrapper {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
    margin-right: 40px;
  }
}

.detailsWrapper {
  composes: col-6 from global;

  @include flexbox(space-between, center);
}

.detailsLabel {
  composes: value;
  margin-right: 10px;
}

.material {
  font-family: $font-family-base;
  font-size: 14px;
  font-weight: 500;
  color: $charcoal-grey;
  white-space: nowrap;
}

.addWrapper {
  @include flexbox(flex-end, center);

  padding: 16px 0 0;
}

.productListHeader,
.photosListHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  font-size: 18px;
  color: $charcoal-grey;

  p {
    color: $steel-grey;
    margin: 0;
  }

  @include media-breakpoint-up(lg) {
    margin: 20px 0;
  }
}

.actions {
  text-align: center;
  margin: 20px 0;
}

.linkButton {
  width: 100%;
}

.fileButton {
  @include media-breakpoint-up(lg) {
    min-width: 140px;
  }
}

.tipContent {
  :global(.react-tooltip-lite) {
    color: $white;
    background-color: $charcoal-grey;
    border-radius: 3px;
    font-size: 11px;

    // override inline styles so the tooltip fills container width and has our standard padding
    display: block !important;
    width: auto !important;
    max-width: 576px !important;
    padding: calc($grid-gutter-width / 2) !important;

    @include media-breakpoint-down(xs) {
      left: 0 !important;
      right: 0 !important;
      width: calc(100% - 30px) !important;
      margin: 0 auto !important;
    }
  }

  :global(.react-tooltip-lite-arrow) {
    border-color: $charcoal-grey;
  }
}

// TODO: replace the above with composes once composes from .scss is fixed in react-scripts
// This bug was mentioned in this create-react-app issue: https://github.com/facebook/create-react-app/issues/7919
// .tooltip {
//   composes: tipContent from "~nvent-web/components/HelpTip/HelpTip.module.scss";
// }

.invalidValue {
  color: $lipstick;
}

.tooltipQuestionMark {
  display: inline-block;
  position: relative;
  margin-left: 5px;
  vertical-align: middle;
  text-align: center;
  background-color: $lipstick;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 18px;
  line-height: 21px;
  color: white;
  margin-top: -2px;
}

.deleteAllButton {
  width: 200px;
  margin-left: auto;
}

.breadcrumbs {
  margin-bottom: 0;
}
