.default {
  position: absolute;
  display: block;
  margin: 0 auto;
  height: 10px;
  line-height: 10px;
  top: 50%;
  margin-top: -5px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}
