@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.container {
  position: fixed;
  right: 0;
  width: 100%;
  top: 60px;
  z-index: 100;
  padding: 0 calc($grid-gutter-width / 2);

  @include media-breakpoint-up(sm) {
    width: auto;
    min-width: 400px;
  }
}

.list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.listItem {
  padding: 0;
  margin: 0;

  &.animateIn {
    animation-name: slideIn;
    animation-duration: 0.2s;
  }

  &.animateOut {
    animation-name: slideOut, squash;
    animation-delay: 0s, 0.1s;
    animation-duration: 0.2s, 0.1s;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100vw);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100vw);
  }
}

@keyframes squash {
  to {
    height: 0;
  }
}
