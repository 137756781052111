@import "~nvent-web/style/variables";

.label {
  font-weight: 600;
  color: $white;
  font-size: 14px;
  padding: 4px 8px;
  background-color: $cerulean-blue;
  border-radius: 5px;
}

.iconWrapper {
  background-color: $cerulean-blue;
  border-radius: 5px;
  padding: 1px 1px 0;
}

.icon {
  display: block;
}
