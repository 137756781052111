@import "~nvent-web/style/variables";

.root {
}

.noContent {
  font-family: $font-family-base;
  font-size: 16px;
  color: $light-blue-grey;
  text-align: center;
}
