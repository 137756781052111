@import "~nvent-web/style/variables";

.checkboxContainer {
  composes: custom-control custom-checkbox from global;
}

.radioContainer {
  composes: custom-control custom-radio from global;
}

.input {
  composes: custom-control-input from global;
}

.label {
  composes: custom-control-label from global;
  color: $charcoal-grey;
}

.wrapper {
  composes: form-group from global;
  margin-bottom: 0;
}
