@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.wrapper {
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  padding-bottom: 15px;
  border-bottom: 1px solid $beige;

  @include media-breakpoint-up(lg) {
    padding-bottom: 25px;
  }
}

.header {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

  @include flexbox(space-between, center);

  padding: 3px 16px;
  background-color: #fff;
  min-height: 60px;
}

.title {
  font-family: $font-family-base;
  font-size: 16px;
  font-weight: 500;
  color: $charcoal-grey;
  flex-grow: 1;
  padding: 0;
  text-align: unset;
  cursor: pointer;
}

.button {
  color: $cerulean-blue;
  cursor: pointer;
  border: none;
  background-color: transparent;
  text-align: center;
  padding: 15px 0;
  margin-left: 15px;

  &:first-of-type {
    color: $faded-red;
    margin-left: 0;
  }
}

.actionsWrapper {
  @include flexbox(space-between, center);
}

.progressWrapper {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.progressFullWrapper {
  display: none;

  @include media-breakpoint-up(lg) {
    display: flex;
    flex: 1;
    margin: 0 55px;
  }
}

.progressFullText {
  display: none;
}
