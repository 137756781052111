@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.noProducts {
  width: 100%;
  margin-top: 235px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: $light-blue-grey;
  line-height: 1.7em;
}

.phoneNumber {
  color: $lipstick;
  margin-left: 5px;
}

.wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
