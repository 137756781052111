@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.card {
  margin-bottom: 0;
}

.desktopCardWrapper {
  box-shadow: none;
}

.desktopCardTitle {
  @include media-breakpoint-up(lg) {
    padding-top: 32px;
    padding-bottom: 9px;
    font-size: 22px;
  }
}

.details {
  padding: 0 16px;

  @include media-breakpoint-up(lg) {
    padding: 0;

    @include flexbox(space-between, center);

    font-weight: 400;
    flex-wrap: wrap;
  }
}

.row {
  margin-bottom: calc($grid-gutter-width / 10);

  @include flexbox(space-between, center);

  @include media-breakpoint-up(lg) {
    margin-right: 16px;
    margin-bottom: 16px;
  }

  @include media-breakpoint-up(xl) {
    margin-right: 48px;
  }
}

.clientHeaderRow {
  @include media-breakpoint-up(lg) {
    margin: 0;
    display: none;
  }
}

.clientNameRow {
  @include media-breakpoint-up(lg) {
    order: -1;
  }
}

.addressRow {
  margin-bottom: 0;

  @include media-breakpoint-up(lg) {
    margin-bottom: 16px;
  }
}

.progressRow {
  margin: 16px 0;

  @include media-breakpoint-up(lg) {
    width: 100%;
    margin: 0;
    padding: 0 $grid-gutter-width;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.label {
  font-family: $font-family-base;
  font-size: 16px;
  color: $cool-grey;
  margin: 0;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.value {
  font-family: $font-family-base;
  font-size: 16px;
  color: $charcoal-grey;
  margin: 0;
}

.referenceNumberLabel {
  composes: label;
}

.referenceNumberValue {
  composes: value;
}

.startLabel {
  composes: label;
}

.startValue {
  composes: value;
}

.clientHeader {
  composes: label;
}

.clientName {
  composes: value;
}

.clientAddress {
  composes: value;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.clientRooms {
  composes: value;
}

.clientPhoneNumber {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}
