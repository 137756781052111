$red: #f00;
$lipstick: #c82b36;
$lipstick-shadow: rgba(197, 38, 46, 0.5);
$faded-red: #e33844;
$rouge: #b21e29;
$darkish-pink: #e45e6f;
$soft-pink: #ffb3bd;
$light-pink: #ffd7dc;
$very-light-pink: #ffeded;
$white: #fff;
$beige: #e5e5e5;
$black: #000;
$cerulean-blue: #007ef1;
$cerulean-two: #0063bf;
$dodger-blue-two: #329dff;
$light-blue: #5d89cb;
$light-gray: #92989f;
$gray: #303538;
$dark-gray: #23282b;
$eastern-blue: #17a2b8;
$pale-grey: #f5f7f9;
$charcoal-grey: #3f4950;
$steel-grey: #737f87;
$roman-silver: #7b868d;
$cool-grey: #9aa7b1;
$light-blue-grey: #c9d1d9;
$light-sky-blue: #dbe2ea;
$light-sky-blue-two: #cde6ff;
$lipstick-two: #c5262e;
$blue-echo: #e8edf2;
$gray-29: #4a4a4a;
$nobel-gray: #9b9b9b;
$medium-green: #3ab44b;
$light-green: #b4e9bc;
$light-red: #ffeded;
$light-lipstick: #ffe6e7;
$shark-grey: #212529;
$font-weight-medium-bold: 500;
$button-border-radius: 20px;
$very-light-blue: #dfefff;
$dodger-blue: #42a4ff;
$baby-blue: #9acfff;
$cerulean: #0066c5;

// bootstrap overrides
$grid-gutter-width: 30px;
$body-bg: $pale-grey;
$font-family-base: "Roboto", sans-serif;
$border-radius: 3px;
$headings-margin-bottom: 11px;
$input-color: $charcoal-grey;
$input-focus-box-shadow: 0 0 0 2px $light-sky-blue;
$btn-border-radius: $button-border-radius;
$btn-border-radius-sm: $button-border-radius;
$btn-border-radius-lg: $button-border-radius;
$btn-padding-y-sm: 8px;
$btn-padding-x-sm: 16px;
$btn-padding-x-lg: 48px;
$btn-focus-width: 0;
$link-hover-color: $cerulean-two;
$link-hover-decoration: none;
$custom-select-border-width: 0;
$custom-select-focus-border-color: $charcoal-grey;
$custom-select-focus-box-shadow: 0 0 0 2px $light-sky-blue;
$theme-colors: (
  "primary-blue": $cerulean-blue,
  "secondary-blue": $light-sky-blue-two,
  "primary-red": $lipstick,
  "secondary-red": $light-pink,
);
$custom-control-gutter: calc(28rem / 16);
$custom-control-indicator-size: calc(18rem / 16);
$custom-control-indicator-bg: $light-blue-grey;
$spinner-width: 2em;
$spinner-border-width: 0.1em;
$spinner-width-sm: 1em;
$spinner-border-width-sm: $spinner-border-width;
$sidebar-padding: 20px;
$container-padding: calc($grid-gutter-width / 2);
$container-padding-lg: $grid-gutter-width;
$react-table-header-z-index: 1000;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
