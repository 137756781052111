@import "~nvent-web/style/variables";

button.archiveButton {
  color: $faded-red;
}

button.archiveButton[disabled] {
  cursor: default;
  color: $light-blue-grey;
}
