@import "~nvent-web/style/variables";

.progress {
  display: flex;
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  background-color: $light-blue-grey;
  border-radius: 10px;
  min-height: 10px;
}

.progressBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1;
  color: $white;
  white-space: nowrap;
  background-color: $medium-green;
  transition: width 0.6s ease;
  border-radius: 10px;
}

.text {
  padding: 3px 8px;
}

.noRadius {
  border-radius: 0;
}

.topRadius {
  border-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.disabled {
  background-color: $roman-silver;
}
