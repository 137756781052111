@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.headerContent {
  display: flex;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.3px;
  color: $pale-grey;
  position: relative;
}

.arrowIconContainer {
  padding-right: calc($grid-gutter-width / 2);
  position: absolute;
  cursor: pointer;
  color: $white;

  @include flexbox(center, center);
}

.titleContainer {
  padding-left: $grid-gutter-width;
}

.picker {
  margin-left: auto;
}
