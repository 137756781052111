@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.wrapper {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow: hidden;
  position: relative;
  margin-bottom: 16px;
}

.header {
  @include flexbox(flex-start, center);

  padding: calc($grid-gutter-width / 2);
}

.title {
  color: $cerulean-blue;
  font-weight: 500;
  padding: 0 0 7px;
  text-align: unset;
  margin: 0;
}

.button {
  color: $cerulean-blue;
  cursor: pointer;
  border: none;
  background-color: transparent;
  text-align: center;
  padding: 15px 25px;

  &:first-of-type {
    margin-left: 0;
  }
}

.link {
}

.actionsWrapper {
  margin-left: auto;

  @include flexbox(space-between, center);
}

.imageWrapper {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: calc($grid-gutter-width / 2);
  overflow: hidden;
}
