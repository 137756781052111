@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.sideMenu {
  background-color: $pale-grey;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  height: 100%;
  margin: 0 (-$sidebar-padding);
}

.addNewProject {
  padding: 0 $sidebar-padding;
  margin-bottom: 20px;
}

.userIcon {
  width: 20px;
}

.menuFooter {
  background-color: $blue-echo;
  height: auto;
  min-height: 133px;
  text-align: center;
  padding-top: 29px;
  bottom: 0;
  width: 100%;
}

.callSupportButton {
  width: 240px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.callSupportIcon {
  fill: white;
  margin-right: 10px;
  width: 20px;
  height: 19px;
}

.callSupportText {
  flex-grow: 1;
}

.footerText {
  padding-top: 11px;

  a {
    color: $lipstick-two;
    font-size: 13px;
    font-weight: normal;
    line-height: normal;
  }
}
