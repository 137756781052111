@import "~nvent-web/style/variables";

.progress {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.toggleButton {
  border: 0;
  background: transparent;

  svg {
    transition: transform 0.35s;
  }
}

.toggleButtonOpen {
  svg {
    transform: rotate(180deg);
  }
}

.wrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 5px;
  background: $white;
}

.content {
  padding: $btn-padding-x-sm;
}

.wrapperShadow {
  box-shadow: 0 2px 10px 0 rgba($black, 0.1);
}

.wrapperBorder {
  border: 2px solid $roman-silver;
}

.info {
  flex: 1;
}

.header {
  display: flex;
}

.title {
  color: $cerulean-blue;
  font-weight: 500;
  text-align: unset;
  margin: 0;
}

.detail {
  font-size: 0.85rem;
}

.contextMenus {
  display: flex;
  gap: 10px;
  align-items: center;
}

.editButton {
  border: 0;
  border-radius: 4px;
  background: transparent;

  &:hover {
    background: $pale-grey;
  }
}

.titleWrapper {
  display: flex;
  gap: 5px;
  align-items: center;
}

.dark {
  background-color: $pale-grey;

  .editButton:hover,
  .button:hover {
    background-color: $light-sky-blue;
  }
}

.disabled {
  color: $roman-silver;
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
