@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.tabsList {
  border-radius: 3px;
  overflow: hidden;
}

.tabsWrapper,
.searchWrapper,
.listWrapper {
  margin-top: calc($grid-gutter-width / 2);
}

.addWrapper {
  @include flexbox(flex-end, center);
}
