@import "~nvent-web/style/variables";

.container {
  position: relative;
  margin-top: 3px;
}

.message {
  position: absolute;
  margin: 0;
  color: $red;
  font-size: 11px;
}
