@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.logoWrapper {
  text-align: center;
  margin-top: 20px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }
}

.logo {
  width: 100%;
  font-size: 24px;
  margin-bottom: 20px;
}

.subtitle {
  color: $lipstick;
  font-size: 14px;
}
