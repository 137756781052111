@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: $black;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.placeholder {
  height: 56px;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.container {
  composes: container-fluid from global;

  @include flexbox(right, center);

  height: 56px;
  background: url("~nvent-web/assets/png/logo-right.png") no-repeat 100% 8%;
}

.hamburger {
  @include flexbox(center, center);

  color: $pale-grey;
  cursor: pointer;
  padding: calc($grid-gutter-width / 4);
}

.titleContainer {
  flex-grow: 1;
}
