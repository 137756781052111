@import "~nvent-web/style/mixins";
@import "~nvent-web/style/variables";

.modal {
  background-color: $white;
}

.content {
  display: grid;
  grid-gap: 16px;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.heading {
  margin-top: 0;
  color: $charcoal-grey;
  font-weight: 600;
}

.button {
  appearance: none;
  border: none;
  background: none;
}

.tile {
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $light-blue-grey;
  background: $white;
  gap: 16px;
  text-align: left;
  position: relative;

  @include media-breakpoint-up(sm) {
    justify-content: space-between;
    text-align: center;
    flex-direction: column;
    height: 230px;
    min-width: 190px;
  }

  &:hover,
  &:active {
    background-color: $pale-grey;
  }

  &:active {
    box-shadow: 0 0 30px 0 rgba(1, 127, 242, 0.1) inset;
    border-color: $cerulean-blue;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;

  &:hover {
    background: $white;
  }

  &:active {
    box-shadow: none;
    border-color: $light-blue-grey;
  }
}

.tileTitle {
  font-size: 16px;
  color: $cerulean-blue;
  font-weight: 500;
  margin: 0 0 4px;
}

.image {
  width: 140px;
  height: 140px;
  flex-shrink: 0;
}

.tileDescription {
  font-size: 10px;
  color: $roman-silver;
  margin: 0;
  font-weight: 400;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
