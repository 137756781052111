@import "~nvent-web/style/mixins";

.imageWrapper {
  width: 100%;
  height: 100%;
}

.imageContainer {
  width: 100%;
  height: 100%;
  display: flex;
}

.image {
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  flex-grow: 1;
}
