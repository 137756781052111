@import "~nvent-web/style/variables";

.nestedElements {
  margin: 20px 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.empty {
  margin: 8px 0;
  color: $roman-silver;
  font-size: 14px;
}

.roomsList {
  border: 2px solid $roman-silver;
  border-radius: 5px;
  padding: 0;
}

.addButton {
  padding: 0 16px;
}

.actionButton {
  color: $cerulean-blue;
  cursor: pointer;
  border: none;
  background-color: transparent;
  text-align: center;
  padding: 0 16px;
  font-weight: 600;
}

.removeAction {
  color: $lipstick;

  &:hover {
    color: $lipstick-two;
  }
}
