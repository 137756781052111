@import "~nvent-web/style/variables";

.container {
  position: relative;
}

.button {
  border: 0;
  background: transparent;
  border-radius: $border-radius;
  cursor: pointer;

  &:hover {
    background: $pale-grey;
  }
}

.item {
  white-space: nowrap;
  cursor: pointer;
  color: $cerulean-blue;
}

.itemListContainer {
  background-color: $white;
  position: absolute;
  box-shadow: 0 2px 10px 0 rgba($black, 0.1);
  margin: 0;
  padding: 8px;
  list-style: none;
  border-radius: $border-radius;
  right: 0;
  top: 30px;
  z-index: 1;
  min-width: 170px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &::before {
    content: "";
    position: absolute;
    top: -8px;
    right: 8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid $white;
  }
}

.buttonItem {
  appearance: none;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  padding: 6px;
  color: $cerulean-blue;
  font-weight: 600;

  &:hover {
    color: $cerulean-two;
  }
}

.linkItem {
  padding: 6px;
  display: block;
  font-weight: 600;
}

.remove {
  color: $lipstick;

  &:hover {
    color: $lipstick-two;
  }
}

.disabled {
  color: $roman-silver;
  cursor: not-allowed;

  &:hover {
    color: $roman-silver;
    cursor: not-allowed;
  }
}
