@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.title {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: $charcoal-grey;
}

.actions {
  @include flexbox(space-between, center);
}

.cancelButton {
  flex-grow: 1;
  margin-right: 5px;
}

.confirmButton {
  flex-grow: 1;
  max-width: 50%;
  margin-left: 5px;
}

.textarea {
  resize: none;
  display: block;
  min-height: 100px;
}
