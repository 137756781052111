@import "~nvent-web/style/variables";

.button {
  composes: btn from global;
  font-weight: $font-weight-medium-bold;
  border: none;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  composes: button;
  line-height: 1.6;

  &:active {
    color: $rouge;
  }
}

// TODO: replace the above with composes once composes from .scss is fixed in react-scripts
// This bug was mentioned in this create-react-app issue: https://github.com/facebook/create-react-app/issues/7919
// .link {
//   composes: button from "../Button.module.scss";
//   line-height: 1.6;

//   &:active {
//     color: $rouge;
//   }
// }
