@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.content {
  @include flexbox(center, center);

  flex-direction: column;
  padding: calc($grid-gutter-width / 2);
}

.info {
  color: $steel-grey;
  text-align: center;
}

.domain {
  composes: info;
  color: $faded-red;
}

.contact {
  composes: info;
  font-size: 12px;
  max-width: 100%;
}
