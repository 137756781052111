@import "~nvent-web/style/variables";

.wrapper {
  composes: form-group from global;
}

.isCorrect {
  border-color: $medium-green !important;
  background: $light-green !important;

  &:focus {
    box-shadow: 0 0 0 2px $medium-green;
  }
}

.isInvalid {
  border-color: $darkish-pink !important;
  background: $light-red !important;

  &:focus {
    box-shadow: 0 0 0 2px $darkish-pink;
  }
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: $cool-grey;
  line-height: normal;
}

.helpTip {
}

.inputContainer {
  position: relative;
}

.input {
  composes: form-control from global;
  border: 0;

  &::placeholder {
    color: $light-blue-grey;
  }
}

.prompt {
  margin: 0;
  color: $light-gray;
  font-size: 11px;
}

.mark {
  position: absolute;
  right: 14px;
  bottom: 0;
  top: 0;
  margin: auto;
}
