@import "~nvent-web/style/variables";

.select {
  composes: custom-select from global;

  &.empty {
    color: $cool-grey;
  }
}

.placeholder {
  .select.required & {
    display: none;
  }
}

.wrapper {
  composes: form-group from global;
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: $cool-grey;
  line-height: normal;
}

.prompt {
  margin: 0;
  color: $light-gray;
  font-size: 11px;
}

// TODO: replace the above with composes once composes from .scss is fixed in react-scripts
// This bug was mentioned in this create-react-app issue: https://github.com/facebook/create-react-app/issues/7919
// .wrapper {
//   composes: wrapper from "./AbstractField.module.scss";
// }
// .label {
//   composes: label from "./AbstractField.module.scss";
// }
// .prompt {
//   composes: prompt from "./AbstractField.module.scss";
// }
