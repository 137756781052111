@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.actionsContainer {
  text-align: right;
}

.actionButton {
  display: inline-block;
  margin-left: 10px;

  @include media-breakpoint-down(sm) {
    margin-top: 10px;
  }
}

.cancelButton {
  margin-top: 20px;
  margin-bottom: 40px;
  min-width: 100px;
}
