@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.tabList {
  composes: react-tabs__tab-list from global;
  margin-left: - calc($grid-gutter-width / 2);
  margin-right: - calc($grid-gutter-width / 2);
}

.title {
  font-family: $font-family-base;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: $charcoal-grey;
  text-align: center;
}
