@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.simpleItem {
  display: flex;
}

.itemCounter {
  font-size: 16px;
  font-weight: 500;
  text-align: right;
  margin-top: 7px;
  margin-left: auto;
}

.menuItem {
  color: $steel-grey;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;

    .itemCounter {
      color: $steel-grey;
    }
  }

  &:active,
  &:hover {
    color: $lipstick-two;
  }
}

.itemText {
  font-size: 16px;
  font-weight: 500;
  padding-top: 9px;
  padding-left: 16px;
}

.itemIcon {
  padding-top: 5px;

  svg {
    width: 17px;
    height: 22px;
  }
}

.activeLink {
  color: $lipstick-two;
  position: relative;

  &::before {
    border-radius: 0 3px 3px 0;
    box-shadow: 0 0 15px 0 $lipstick-two;
    background-color: $lipstick;
    content: "";
    height: 38px;
    left: -20px;
    position: absolute;
    width: 7px;
  }
}
