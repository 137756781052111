@import "~nvent-web/style/variables";

.select {
  composes: custom-select from global;
  background-color: transparent;
  cursor: pointer;
  height: auto;
  padding-top: 0;
  padding-bottom: 0;

  &:focus {
    border-color: inherit;
    box-shadow: none;
  }
}

.dark {
  color: $white;
  background-image: url("~nvent-web/assets/svg/select-arrow.svg");
  background-repeat: no-repeat;

  option {
    background-color: $black;
  }
}
