@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.wrapper {
  display: none;

  @include media-breakpoint-up(lg) {
    @include flexbox(normal, center);

    padding-top: 32px;
    padding-bottom: 22px;
  }
}

.picker {
  margin-left: auto;
}
