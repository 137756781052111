@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.button {
  margin-top: 20px;
  min-width: 100px;
  margin-left: auto;
}

.insulationCard {
  @include media-breakpoint-up(xl) {
    max-width: 100%;
    width: calc(100% - 15px);
  }
}

.smallTiles {
  max-width: 400px;
}

.submitButton {
  min-width: 25%;
  display: block;
  margin-left: auto;
  margin-top: 20px;
}
