@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.actions {
  @include flexbox(space-between, center);
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, max-content);
  column-gap: 30px;
  justify-content: stretch;
  align-items: center;
}
