@import "~nvent-web/style/variables";

.image {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $cerulean-blue;
  color: $white;
  text-transform: uppercase;
  pointer-events: none;
}
