.cancelBtn {
  flex-basis: 50%;
  flex-grow: 1;
  margin-right: 5px;
}

.yesBtn {
  flex-basis: 50%;
  flex-grow: 1;
  margin-left: 5px;
}
