@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.wrapper {
  width: 100%;
  background-color: $pale-grey;
  padding-top: 20px;
}

.numbersList {
  font-size: 18px;
  color: $steel-grey;
  width: 100%;
  text-align: left;

  hr {
    border-top: none;
    border-bottom: 1px solid #e1e1e1;
    width: 90%;
    margin: 27px auto;
    clear: both;
  }

  @include media-breakpoint-up(sm) {
    hr {
      width: 100%;
    }
  }
}

.numbersHeader {
  width: 100%;
  text-align: center;
  clear: both;

  @include media-breakpoint-up(sm) {
    text-align: left;
  }
}

.biggerFlag {
  height: 25px;
  width: 40px;
  margin-right: 10px;
}

.countryWithNumber {
  width: 165px;
  text-align: left;
  margin: 20px auto;
  float: none;

  @include media-breakpoint-up(sm) {
    margin: 20px 40px 20px 0;
    float: left;
  }
}

.phoneNumber {
  font-weight: 300;
  height: 100%;
  line-height: 25px;
  font-size: 14px;
}
