@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.overlay {
  position: fixed;
  inset: 0;
  z-index: 1000;
  overflow: auto;
  background: rgba($black, 0.3);

  @include flexbox(center, flex-start);

  &.centered {
    @include flexbox(center, center);
  }
}

.modal {
  composes: container-fluid from global;

  @include media-breakpoint-up(lg) {
    max-width: map-get($container-max-widths, sm);
  }

  &.wide {
    max-width: 1200px;

    @include media-breakpoint-up(lg) {
      max-width: 1200px;
    }
  }
}

.modalInner {
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
  background-color: $pale-grey;
  position: relative;

  &.centered {
    @include media-breakpoint-up(sm) {
      width: 500px;
    }
  }
}

.header {
  @include flexbox(flex-end, center);
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  appearance: none;
  border: none;
  background: none;
  padding: 0;
}
