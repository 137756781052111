@import "~nvent-web/style/variables";

.title {
  font-size: 20px;
  color: $charcoal-grey;
  font-weight: 400;
}

.description {
  color: $steel-grey;
  padding: 20px 0;
  margin: 0;
}
