@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.userInfo {
  padding: 15px $sidebar-padding;
  margin-bottom: 25px;
  background-color: #e4e9ee;

  &Inner {
    @include flexbox(space-between, flex-start);
  }
}

.userName,
.userEmail,
.userCompany {
  line-height: normal;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userName {
  font-size: 18px;
  font-weight: 500;
  color: $charcoal-grey;
  margin-bottom: 5px;
}

.userEmail,
.userCompany {
  font-size: 14px;
  font-weight: normal;
  color: $steel-grey;
  margin-bottom: 5px;
}

.userAccount {
  font-size: 14px;
  color: $charcoal-grey;
  margin-bottom: 0;

  &Type {
    font-weight: 500;
    color: $lipstick;
  }
}

.imageWrapper {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: relative;
  flex-shrink: 0;
}

.image {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid $lipstick;
  background-color: $white;
  overflow: hidden;
}

.companyImage {
  position: absolute;
  bottom: 0;
  left: 27px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid $lipstick;
  background-color: $white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.userTextInfo {
  overflow: hidden;
}

.initials {
  font-size: 20px;
  color: $lipstick;
}
