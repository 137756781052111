@import "~nvent-web/style/variables";

.input {
  display: none;
}

.label {
  margin: 0;
}

.spinner {
  margin-left: calc($grid-gutter-width / 4);
}

// TODO: replace the above with composes once composes from .scss is fixed in react-scripts
// This bug was mentioned in this create-react-app issue: https://github.com/facebook/create-react-app/issues/7919
// .spinner {
//   composes: spinner from "../Button.module.scss";
// }
