@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.actions {
  @include flexbox(space-between, center);
}

.wrapper {
  composes: row from global;
}

.column {
  composes: col-lg-6 from global;
}

.buttonsColumn {
  composes: column;
  margin-left: auto;
}

.columnFullWidth {
  width: 100%;
  padding-left: calc($grid-gutter-width / 2);
}

.quickHeatingCheckbox {
  @include media-breakpoint-up(lg) {
    padding-top: $grid-gutter-width;
  }
}

.buttons {
  width: 100%;
}

.requiredFieldLabel {
  margin-top: 16px;
}
