@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.menu {
  padding: 0 $sidebar-padding;
  flex: 1 1 auto;
}

.menuItem {
  color: $steel-grey;
  display: flex;
  margin-bottom: 16px;

  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;

    .itemCounter {
      color: $steel-grey;
    }
  }

  &:active,
  &:hover {
    color: $lipstick-two;
  }
}

.simpleItem {
  display: flex;
}

.logoutItem {
  composes: menuItem;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0 0 16px 0;

  &:active,
  &:focus {
    outline: none;
  }
}

.itemText {
  font-size: 16px;
  font-weight: 500;
  padding-top: 9px;
  padding-left: 16px;
  line-height: normal;
}

.itemIcon {
  padding-top: 5px;

  svg {
    width: 17px;
    height: 22px;
  }
}
