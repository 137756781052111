@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.helpIcon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  border-radius: 50%;
  border: none;
  background-color: $cool-grey;
  font-size: 18px;
  line-height: 21px;
  margin: 0 5px;
  cursor: pointer;
  color: $white;
}
