@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";
@import "./ProPageCommon";

.wrapper {
  padding: 50px 20px;
  width: 100%;
  max-width: 740px;
  margin: 0 auto;

  @include media-breakpoint-down(xs) {
    padding: 30px 0;
  }
}

.iconsRow {
  margin: 40px 0;
  width: 100%;

  @include flexbox(left, stretch);

  flex-wrap: wrap;
}

.column {
  width: 20%;
  padding: 0 5px;

  @media (max-width: 670px) {
    width: 50%;
  }
}

.columnDescription {
  font-size: 14px;
  text-align: center;
  color: $charcoal-grey;
  padding: 0 5px;

  strong,
  a {
    font-weight: bold;
    color: $rouge;
  }
}

.columnIcon {
  height: 115px;
  width: 100%;
  background-position: top center;
  background-size: auto 60%;
  background-repeat: no-repeat;
}

.awardIcon {
  composes: columnIcon;
  background-image: url("~nvent-web/assets/png/award-icon.png");
}

.warrantyIcon {
  composes: columnIcon;
  background-image: url("~nvent-web/assets/png/warranty-icon.png");
}

.computerIcon {
  composes: columnIcon;
  background-image: url("~nvent-web/assets/png/computer-icon.png");
}

.discountIcon {
  composes: columnIcon;
  background-image: url("~nvent-web/assets/png/discount-icon.png");
}

.handsIcon {
  composes: columnIcon;
  background-image: url("~nvent-web/assets/png/hands-icon.png");
}

.signUpBarWrapper {
  width: 100%;
  overflow: hidden;
}

.signUpBar {
  width: 100%;
  min-height: 154px;
  background-color: $rouge;
  position: relative;
  background-image: url("~nvent-web/assets/png/red-background-signup-desktop.png");
  background-repeat: no-repeat;
  background-size: cover;

  @include media-breakpoint-down(xs) {
    background-image: url("~nvent-web/assets/png/red-background-signup-mobile.png");
  }
}

.signUpBarContent {
  position: relative;
  width: 100%;
  max-width: 740px;
  padding: 20px 30px;
  margin: 0 auto;
}

.signUpTitle {
  font-size: 24px;
  color: white;
  font-weight: 500;
  letter-spacing: -0.38px;

  @include media-breakpoint-down(xs) {
    font-size: 18px;
    letter-spacing: -0.29px;
    margin-top: 0;
  }
}

.signUpSubtitle {
  color: white;
  width: 300px;
  font-size: 14px;

  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}

.signUpButton {
  position: absolute;
  display: inline-block;
  right: 30px;
  top: 50%;
  margin-top: -20px;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  border-radius: 100px;
  background-color: $pale-grey;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: $rouge;
  border: none;

  &:focus,
  &:hover {
    color: $rouge;
  }

  @include media-breakpoint-down(xs) {
    position: relative;
    margin-left: 30px;
    height: 34px;
    line-height: 34px;
    margin-top: 5px;
  }
}

.stepsRow {
  margin-bottom: 30px;
  width: 100%;
  height: auto;

  @include flexbox(flex-start, top);
}

.step {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: $lipstick;
  border-radius: 50%;
  color: white;
  font-size: 1.5em;
  margin-right: 35px;

  @include media-breakpoint-down(xs) {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 1.3em;
  }
}

.stepsHeader {
  margin-bottom: 20px;
  color: $rouge;

  @include media-breakpoint-down(xs) {
    font-size: 18px;
  }
}

.stepHeader {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.35px;
  color: $charcoal-grey;
  margin-top: 0;

  @include media-breakpoint-down(xs) {
    h2 {
      font-size: 18px;
    }
  }
}

.stepList {
  margin-left: -20px;

  a {
    color: $rouge;
  }

  @include media-breakpoint-down(xs) {
    font-size: 14px;
  }
}

.stepNumber {
  height: 100%;
  width: 50px;
  margin-right: 35px;

  @include media-breakpoint-down(xs) {
    width: 45px;
    margin-right: 15px;
  }
}

.bannerImage {
  background-image: url("~nvent-web/assets/png/non-certified-pro-banner-desktop.png");

  @include media-breakpoint-down(xs) {
    background-image: url("~nvent-web/assets/jpg/non-certified-pro-banner-mobile.jpg");
  }
}

.bannerText {
  margin-bottom: 90px;

  @include media-breakpoint-down(xs) {
    padding: 77px 10px 30px 10px;
    margin-bottom: 0;
  }
}
