@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.root {
  composes: alert alert-dismissible from global;
  margin-top: 5px;
}

@each $name, $color in $theme-colors {
  .#{$name} {
    composes: alert-#{$name} from global;
  }
}

.label {
}

.closeButton {
  composes: close from global;
}
