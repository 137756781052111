@import "~nvent-web/style/variables";

.modalContent {
  text-align: center;
}

.heading {
  font-size: 22px;
  font-weight: 900;
  letter-spacing: 0.4px;
  color: $charcoal-grey;
}

.info {
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.3px;
  color: $steel-grey;
}

.rect {
  background-color: $charcoal-grey;
  border-radius: 30px;
  position: relative;
  height: 105px;
  margin: 50px 0;
}

.logo {
  position: relative;
  max-width: 150px;
  bottom: 20%;
  margin: auto;
}

.addIcon {
  max-width: 18px;
  vertical-align: middle;
}

.closeIconWrapper {
  display: flex;
  justify-content: flex-end;
}

.closeIcon {
  float: right;
  cursor: pointer;
}
