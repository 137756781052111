@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.wrapper {
  overflow: hidden;
  padding: calc($grid-gutter-width / 6);
  padding-bottom: calc($grid-gutter-width / 3);
  position: relative;
  flex-basis: 100%;

  @include media-breakpoint-up(lg) {
    flex-basis: 50%;
  }

  @include media-breakpoint-up(xl) {
    flex-basis: 33.33%;
  }
}

.container {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: $white;
  padding: calc($grid-gutter-width / 2);
  position: relative;
}

.title {
  font-size: 18px;
  font-weight: 500;
  color: $charcoal-grey;
  margin-bottom: calc($grid-gutter-width / 2);
}

.spec {
  font-size: 12px;
  font-weight: 400;
  color: $charcoal-grey;
  display: block;
  width: 100%;
  margin: 0;
  clear: both;

  p {
    margin-bottom: 0;
  }
}

.measurement,
.unit {
  margin-left: calc($grid-gutter-width / 6);
}

.quantity {
  color: $lipstick;
}

.productCableLength {
  color: $medium-green;
  margin-left: 10px;
}

.product {
  margin-bottom: calc($grid-gutter-width / 2);
}

.productsList {
  margin-bottom: 25px;
}

.productItem {
  margin: 10px 0;
  width: 100%;
  position: relative;

  @include flexbox(space-between, center);
}

.productIdentifier {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $charcoal-grey;
}

.bundleSpecs {
  width: 100%;
  display: inline-block;
  margin-bottom: 46px;
}

.commercialDescription {
  height: 100%;
  display: block;
  color: $medium-green;
  font-size: 14px;
  width: 100%;
  margin-bottom: 10px;
}

.addButton {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.helpTip {
  transform: translateY(-2px);
}

.productImg {
  height: 150px;
  width: 100%;
  margin-bottom: 15px;
  background-size: contain;
  background-repeat: no-repeat;
}

.removeButton {
  color: $lipstick;
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
  margin-left: calc($grid-gutter-width / 3);
}
