@import "~nvent-web/style/variables";

.progressWrapper {
  background-color: $light-blue-grey;
  position: sticky;
  bottom: 0;
}

.progress {
  height: 6px;
  background-color: $medium-green;
}
