@import "~nvent-web/style/variables";

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.formTitle {
  font-size: $font-size-lg;
  font-weight: 600;
  color: $charcoal-grey;
  margin-bottom: 0;
}

.formDescription {
  color: $roman-silver;
  margin-bottom: 0;
}

.noLevelDescription {
  color: $cerulean-blue;
  margin-bottom: 0;
}

.buttonsContainer {
  display: flex;
  gap: 1rem;
  width: 100%;

  button {
    flex-grow: 1;
  }
}
