@import "~nvent-web/style/variables";

.wrapper {
  composes: form-group has-search from global;
  margin-bottom: 0;
}

.inputContainer {
  position: relative;
}

.input {
  composes: form-control from global;
  border: 0;

  &::placeholder {
    color: $light-blue-grey;
  }
}

.wrapper .input {
  padding-left: 36px;
}

.icon {
  composes: form-control-feedback from global;
}

.wrapper .icon {
  position: absolute;
  top: 14px;
  left: 12px;
  z-index: 2;
  display: block;
  width: 12px;
  height: 12px;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
