@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.container {
  composes: container-fluid from global;
  padding-top: $container-padding;
  padding-bottom: $container-padding;

  @include fullHeight;

  @include media-breakpoint-up(lg) {
    max-width: 300px + 2 * $container-padding-lg;
    padding-top: $container-padding-lg;
    padding-bottom: $container-padding-lg;
  }
}

.wrapper {
  display: flex;
  flex-grow: 1;
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
}
