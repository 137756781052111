@import "./variables";
@import "~bootstrap/scss/mixins";

@mixin fullHeight() {
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

@mixin flexbox($justify-content, $align-items) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin svgSizes($width, $height) {
  height: $width + px;
  width: $height + px;
}

@mixin textOverflow() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin feature-enabled($name) {
  :global(.feature-#{$name}) & {
    @content;
  }
}

// feature-enabled equivalent for styles outside of CSS modules
@mixin feature-enabled-global($name) {
  .feature-#{$name} & {
    @content;
  }
}
