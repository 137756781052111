@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.title {
  margin-top: 17px;
  display: block;
}

.heatResistance {
  font-size: 16px;
  letter-spacing: -0.3px;
  color: $steel-grey;
  font-weight: normal;
  margin-bottom: 5px;
}

.actions {
  @include flexbox(space-between, center);
}

.cancelBtn {
  flex-grow: 1;
  margin-right: 5px;
}

.yesBtn {
  flex-grow: 1;
  margin-left: 5px;
}

.heatResistanceRow {
  @include flexbox(space-between, center);

  width: 100%;
}

.label {
  font-size: 12px;
  letter-spacing: -0.2px;
  color: $steel-grey;
  margin: 0;
}

.value {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.2px;
  color: $charcoal-grey;
}

.checkCircle {
  background-color: $white;
  height: 33px;
  width: 33px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.info {
  flex-direction: column;
  cursor: pointer;
}

.testDataWrapper {
  @include flexbox(space-between, center);
}

.testData {
  flex: 1;
  padding-right: 17px;
}

.container {
}

.line {
  width: 2px;
  height: 60px;
  position: absolute;
  background-color: $white;
  bottom: -60px;
}

.labelWrapper {
  @include flexbox(space-between, center);
}

.labelInner {
  flex-grow: 1;
}
