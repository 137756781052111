@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";
@import "./ProPageCommon";

.wrapper {
  padding: 0 20px 50px 20px;
  width: 100%;
  max-width: 740px;
  margin: 0 auto;

  @include media-breakpoint-down(xs) {
    padding: 30px 0;
  }
}

.header {
  margin-top: 50px;

  @include media-breakpoint-down(xs) {
    margin-top: 0;
  }
}

.iconWrapper {
  height: 100%;
  display: inline-block;
}

.termsList {
  text-align: left;
  padding-left: calc($grid-gutter-width / 2);
  padding-right: calc($grid-gutter-width / 2);
}

.headerImg {
  display: block;
  width: 120px;
  height: auto;
  margin: 30px auto;
}

.headerRow {
  margin: 40px 0;
}

.headerText {
  composes: columnRight;
  padding: 0;
}

.promotionsHeader {
  margin-top: 0;
  color: $rouge;
  margin-bottom: 10px;
}

.bannerImage {
  background-image: url("~nvent-web/assets/png/certified-pro-banner-desktop.png");

  @include media-breakpoint-down(xs) {
    background-image: url("~nvent-web/assets/jpg/certified-pro-banner-mobile.jpg");
  }
}

.bannerText {
  margin-bottom: 90px;

  @include media-breakpoint-down(xs) {
    padding: 45px 10px 30px 10px;
    margin-bottom: 0;
  }
}
