@import "./variables";
@import "./mixins";
@import "./vendors";
@import "./typography";

html {
  height: 100%;
}

body {
  min-height: 100%;
}

body,
#root {
  @include fullHeight;
}

.container-fluid {
  max-width: none;
  padding-left: $container-padding;
  padding-right: $container-padding;

  @include media-breakpoint-up(lg) {
    padding-left: $container-padding-lg;
    padding-right: $container-padding-lg;
  }
}

.container-fluid-width {
  margin-left: -$container-padding;
  margin-right: -$container-padding;

  @include media-breakpoint-up(lg) {
    margin-left: -$container-padding-lg;
    margin-right: -$container-padding-lg;
  }
}
