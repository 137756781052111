@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.container {
  color: $charcoal-grey;
  padding: calc($grid-gutter-width / 2);
  width: 100%;
  max-width: $modal-lg;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    padding-top: map-get($spacers, 4);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: map-get($spacers, 4);
  margin-top: map-get($spacers, 4);
}

.tokenInvalidTitle {
  font-size: $h4-font-size;
  font-weight: bold;
  text-align: center;
}

.tokenInvalidDescription {
  font-size: $font-size-base;
  text-align: center;
}

.projectName {
  font-size: $h4-font-size;
  text-align: center;
}

.statusIndicator {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: map-get($spacers, 2);
}

.spinner {
  width: 15px;
  height: 15px;
}

.downloadButton {
  font-size: $h5-font-size;
  margin: map-get($spacers, 3) auto;
  padding: map-get($spacers, 4) $spacer;
  width: 408px;
}
