@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.content {
  :global(.react-tooltip-lite) {
    color: $white;
    background-color: $charcoal-grey;
    border-radius: 3px;
    font-size: 11px;
    display: block !important;
    width: auto !important;
    max-width: 576px !important;
    padding: calc($grid-gutter-width / 2) !important;
  }

  :global(.react-tooltip-lite-arrow) {
    border-color: $charcoal-grey;
  }
}
