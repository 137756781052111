@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.wrapper {
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow: hidden;
  position: relative;
  margin-bottom: 16px;
}

.header {
  @include flexbox(space-between, center);

  padding: 16px;
}

.link {
  flex-grow: 1;
}

.title {
  color: $cerulean-blue;
  cursor: pointer;
}

.subtitle {
  font-size: 14px;
  cursor: auto;
}

.arrowWrapper {
  @include svgSizes(24, 24);
  @include flexbox(center, center);

  cursor: pointer;
  border: none;
  background-color: transparent;

  .up {
    color: $gray-29;
  }

  .down {
    color: $cool-grey;
  }
}

.footer {
  @include flexbox(space-between, center);

  border-top: 2px solid $beige;
}

.button {
  color: $cerulean-blue;
  cursor: pointer;
  border: none;
  border-left: 2px solid $beige;
  background-color: transparent;
  width: 100%;
  text-align: center;
  padding: 9px 0;

  &:first-of-type {
    border-left: 0;
  }
}

.imageWrapper {
  width: 37px;
  height: 37px;
  border-radius: 100%;
  overflow: hidden;
}
