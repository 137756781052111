@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.tabList {
  composes: react-tabs__tab-list from global;
  border-radius: 3px;
  overflow: hidden;
}

:global {
  .react-tabs__tab {
    background: $white;
  }

  .react-tabs__tab--selected {
    font-weight: 500;
    background: $light-lipstick;
    border-bottom-color: $lipstick-two;
    color: $lipstick-two;
  }
}
