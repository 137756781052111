@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.tabsList {
  border-radius: 3px;
  overflow: hidden;
}

.tabsWrapper,
.listWrapper {
  margin-top: calc($grid-gutter-width / 2);
}

.archivedProjectsHeader {
  position: relative;
  width: 100%;
  padding: 16px;
  color: $cool-grey;
  font-size: 16px;
  font-weight: 500;
}

.archivedProjectsArrow {
  position: absolute;
  right: 23px;
  top: 50%;
  margin-top: -4px;
}

.arrowWrapper {
  @include svgSizes(24, 24);

  position: absolute;
  text-align: center;
  right: 16px;
  top: 50%;
  margin-top: -12px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.up {
  color: $gray-29;
  transform: rotateZ(180deg);
}

.down {
  color: $cool-grey;
}
