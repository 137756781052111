@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.banner {
  margin-top: 20px;
  height: auto;
  min-height: 300px;
  width: 100%;
  border-radius: 2px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-down(xs) {
    height: auto;
  }
}

.bannerImg {
  position: absolute;
  top: 0;
  left: 45%;
  width: 55%;
  height: 100%;
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;

  @include media-breakpoint-down(xs) {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    height: 215px;
    background-position: center center;
  }
}

.bannerText {
  width: 50%;
  height: 100%;
  padding: 30px;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.35px;
  color: $charcoal-grey;

  @include media-breakpoint-down(xs) {
    font-size: 18px;
    width: 100%;
    padding: 77px 10px 30px 10px;
  }
}

.bannerIcon {
  width: 75px;
  height: 70px;
  position: absolute;
  bottom: 30px;
  left: 25%;
  margin-left: -38px;
  background-image: url("~nvent-web/assets/png/certified.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include media-breakpoint-down(xs) {
    margin-top: -47px;
    margin-left: -50.5px;
    width: 101px;
    height: 94px;
    top: 215px;
    left: 50%;
  }
}

.certifiedIcon {
  inset: auto 20px 20px auto;
  margin-left: 0;
  margin-top: 0;

  @include media-breakpoint-down(xs) {
    margin-top: -35px;
    margin-left: -37.5px;
    width: 75px;
    height: 70px;
    top: 215px;
    left: 50%;
  }
}
