@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.row {
  display: flex;
  margin: 0 - calc($grid-gutter-width / 4);
}

.shortField {
  flex-grow: 1;
  flex-basis: 100%;
  padding: 0 calc($grid-gutter-width / 4);
}

.userInfoWrapper {
  composes: col-12 from global;
  padding: 15px 0;
}

.userInfoLabel {
  composes: col-12 from global;

  @include media-breakpoint-up(lg) {
    font-size: 22px;
  }
}

.title {
  letter-spacing: -0.3px;
  color: $cool-grey;
  margin-bottom: 15px;
}

.certifiedProNumberTitle {
  composes: title;
}

.emailTitle {
  composes: title;
}

.value {
  letter-spacing: -0.3px;
  color: $charcoal-grey;
  margin-left: 10px;
  margin-bottom: 15px;
}

.certifiedProNumberValue {
  composes: value;
}

.emailValue {
  composes: value;
}

.form {
  composes: row from global;
  margin-bottom: $grid-gutter-width;
}

.column {
  composes: col-lg-6 from global;
}

.checkbox {
  padding-top: 2px;
}

.checkboxContainer {
  @include media-breakpoint-down(md) {
    z-index: 2;
  }
}

.fullWidth {
  composes: row col-12 from global;
}

.separator {
  display: block;
  right: 0;
  margin: 0.5em auto;
  border: none;
  color: $light-sky-blue;
  background-color: $light-sky-blue;
  height: 2px;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.photoWrapper {
  composes: col-12 from global;
}

.photo {
  composes: col-lg-6 from global;

  @include flexbox(center, center);

  margin-bottom: calc($grid-gutter-width / 2);
}

.fileButton {
  @include flexbox(center, center);

  width: 100px;
  height: 100px;
  padding: 0;
  background-color: $light-blue-grey;
  color: $steel-grey;
  cursor: pointer;
}

.fileNoTheme {
  background-color: transparent;
}

.companyName {
  border-radius: 3px;
  padding: 7px 12px;
  overflow: hidden;
}

.companyNameTitle {
  composes: title;
  margin-bottom: 3px;
}

.modal {
  text-align: center;
  color: $steel-grey;
}

.modalContent {
  @include flexbox(center, center);

  flex-direction: column;
  padding: calc($grid-gutter-width / 2);
}

.modalContact {
  margin: 5px 0 13px;
  font-weight: 500;

  a {
    color: $lipstick;
    margin: 7px;
    display: inline-block;
  }
}

.modalDescription {
  margin-top: 20px;
}

.incompleteProfileAlert {
  composes: alert alert-danger from global;
}
