@import "~nvent-web/style/variables";

.testButton {
  font-weight: 500;
  border: 2px solid $cerulean-blue !important;
  border-radius: 25px;
  padding: 7px 15px !important;
  outline: none;

  &:hover {
    background-color: $cerulean-blue;
    color: $white;
  }

  &:not(:disabled, .disabled):active,
  &:not(:disabled, .disabled):focus {
    background-color: #0060b9;
    color: $white;
    outline: none;
  }
}
