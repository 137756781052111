@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.wrapper {
  padding: 30px;

  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.modal {
  width: 100%;
  max-width: 700px;
}

.title {
  margin-bottom: 30px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
  }
}

.image {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.buttonWrapper {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.button {
  min-width: 100px;

  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-top: 20px;
  }
}
