@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.signUpBar {
  width: 100%;
  background-color: $rouge;
  position: relative;
  background-image: url("~nvent-web/assets/png/catalog-background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.signUpBarContent {
  position: relative;
  width: 100%;
  max-width: 740px;
  padding: 20px 30px;
  padding-right: 240px;
  margin: 0 auto;

  @include media-breakpoint-down(xs) {
    padding: 20px 30px;
  }
}

.signUpTitle {
  font-size: 24px;
  color: white;
  font-weight: 500;
  letter-spacing: -0.38px;

  @include media-breakpoint-down(xs) {
    font-size: 18px;
    letter-spacing: -0.29px;
    margin-top: 0;
  }
}

.signUpSubtitle {
  color: white;
  width: 300px;
  font-size: 14px;
}

.signUpButton {
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -20px;
  width: 180px;
  height: 40px;
  line-height: 40px;
  border-radius: 100px;
  background-color: $pale-grey;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #f68713;
  border: none;

  @include media-breakpoint-down(xs) {
    position: relative;
    margin-left: 30px;
    width: 170px;
    height: 34px;
    line-height: 34px;
    margin-top: 5px;
  }

  &:focus {
    color: #f68713;
  }
}
