@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.itemListHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 15px 0;

  @include media-breakpoint-up(lg) {
    margin: 20px 0;
  }
}

.itemListTitle {
  color: $charcoal-grey;
  font-size: 18px;
  margin: 0;
}

.button {
  @include media-breakpoint-up(lg) {
    min-width: 140px;
  }
}
