@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.iconImg {
  width: 60px;
  height: auto;

  @include media-breakpoint-down(xs) {
    width: 80px;
    margin: 20px 0;
  }
}

.iconWrapper {
  height: 100%;
  display: inline-block;
}

.row {
  width: 100%;
  display: flex;
  flex: 130px 0;
  margin-bottom: 30px;

  @include media-breakpoint-down(xs) {
    display: block;
    text-align: center;
  }
}

.columnRight {
  padding: 10px 0 10px 50px;
  padding-left: 50px;
  font-size: 15px;
  text-align: left;
  color: $charcoal-grey;

  // FormattedHTMLMessage styling
  :global(.red) {
    color: $rouge;
  }

  strong,
  a {
    font-weight: bold;
    color: $rouge;
  }

  @include media-breakpoint-down(xs) {
    padding-left: 0;
  }
}
