@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: calc($grid-gutter-width / 2);
  padding-bottom: calc($grid-gutter-width / 2);
}

.spinner {
  color: $light-blue-grey;
}
