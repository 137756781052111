@import "~nvent-web/style/variables";

.content {
  text-align: center;
  padding: 15px;
}

.title {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: $charcoal-grey;
  margin: 0;
}

.message {
  margin: 20px 0;
}

.button {
  display: inline-block;
}
