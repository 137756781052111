// Based on "~react-tabs/style/react-tabs.scss"
// not importing it, because we'd have to reset most of what it does,
//  which only obscures the actual way we want it to be displayed

.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    display: flex;
    align-items: stretch;
    margin: 0 0 calc($grid-gutter-width / 2) 0;
    padding: 0;
  }

  &__tab {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    list-style: none;
    cursor: pointer;
    padding: calc($grid-gutter-width / 2) 0;
    color: $steel-grey;
    text-align: center;

    &--selected {
      font-weight: 500;
      background: $white;
      border-bottom: 2px solid $cerulean-blue;
      color: $cerulean-blue;
    }

    &--disabled {
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}
