@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc($grid-gutter-width / 3);

  @include media-breakpoint-up(xl) {
    margin-right: calc($grid-gutter-width / -2);
  }
}

.iconContainer {
  color: $charcoal-grey;

  &.selected {
    color: $medium-green;
  }
}

.arrowIconContainer {
  color: $cool-grey;

  &.selected {
    color: $medium-green;
  }
}

.container {
  width: 100%;
  max-width: 100%;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: $white;
  overflow: hidden;
  padding: calc($grid-gutter-width / 2);
  margin-bottom: calc($grid-gutter-width / 3);

  @include flexbox(space-between, center);

  max-height: 80px;
  cursor: pointer;

  @include media-breakpoint-up(xl) {
    max-width: 400px;
    margin-right: 1%;
  }

  &:last-child {
    margin-right: 0;
  }

  &.selected {
    border: 2px solid $medium-green;
  }
}

.content {
  text-align: left;
  flex-basis: 250px;
  padding-left: 10px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  color: $steel-grey;
  margin: 0;

  &.selected {
    color: $medium-green;
  }
}

.description {
  font-size: 11px;
  font-weight: 300;
  color: $light-blue-grey;
  margin: 0;
}

.arrowIcon {
  width: 15px;
  height: 13px;
}
