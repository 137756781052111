@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.productName {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.3px;
  color: $charcoal-grey;
}

.actions {
  text-align: center;
  margin: 20px 0;
}

.button {
  width: 100%;
}
