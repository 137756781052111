@import "./variables";

h1 {
  color: $charcoal-grey;
  font-size: 22px;
  font-weight: $headings-font-weight;
  line-height: 1;
  text-align: left;
  margin-bottom: 0;
}

h2 {
  margin: calc($grid-gutter-width / 2) 0;
  padding: 0;
  color: $steel-grey;
  font-size: 18px;
  font-weight: 500;
}

a {
  font-weight: $font-weight-medium-bold;

  &:focus,
  &:active {
    color: $dodger-blue-two;
  }
}
