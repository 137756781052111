@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.title {
  margin-top: 0;
  color: $charcoal-grey;
  font-weight: 600;
}

.input {
  composes: form-control from global;
  border: 0;
}

.actions {
  margin-top: 16px;

  @include flexbox(space-between, center);
}

.cancelBtn {
  flex-grow: 1;
  margin-right: 5px;
}

.yesBtn {
  flex-grow: 1;
  margin-left: 5px;
}

.loading {
  height: 36px;
}
