@import "~nvent-web/style/mixins";

.photosWrapper {
  position: relative;
  composes: row from global;
  margin-right: -5px;
  margin-left: -5px;
}

.photoWrapper {
  composes: col-4 col-sm-3 from global;
  padding: 5px;
  cursor: pointer;

  @include media-breakpoint-up(md) {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.photo {
  padding-top: 100%;
  background-size: cover;
}

.deleteIcon {
  top: 5px;
  right: 5px;
}
