@import "~nvent-web/style/variables";

.progress {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.editButton {
  border: 0;
  border-radius: 4px;
  background: transparent;

  &:hover {
    background: $pale-grey;
  }
}

.wrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  background: $white;
  border-radius: 5px;
}

.dark {
  background-color: $pale-grey;

  .editButton:hover,
  .link:hover,
  .button:hover {
    background-color: $light-sky-blue;
  }
}

.nestedRoom {
  border-radius: 0;

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.content {
  padding: $btn-padding-x-sm;
}

.info {
  flex: 1;
  display: flex;
  gap: 8px;
  min-width: 0;
  align-items: center;
}

.roomIcon {
  flex-shrink: 0;
}

.header {
  display: flex;
}

.title {
  color: $cerulean-blue;
  font-weight: 500;
  text-align: unset;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disabled {
  color: $roman-silver;

  &:hover {
    color: $roman-silver;
  }
}

.detail {
  font-size: 0.85rem;
}

.contextMenus {
  display: flex;
  gap: 10px;
  align-items: center;
}

.titleWrapper {
  display: flex;
  gap: 5px;
  align-items: center;
  min-width: 0;
}

.open {
  border-radius: $border-radius;

  &:hover {
    background: $pale-grey;
  }
}
