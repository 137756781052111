@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.helpTip {
  position: relative;
  vertical-align: middle;
  text-align: center;
  background-color: $cool-grey;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 18px;
  line-height: 21px;
  margin: 0 5px;
  cursor: pointer;
  color: #fff;

  &.inline {
    display: inline-block;
    margin: 0 5px;
  }
}

.tipContent {
  :global(.react-tooltip-lite) {
    color: $white;
    background-color: $charcoal-grey;
    border-radius: 3px;
    font-size: 11px;

    // override inline styles so the tooltip fills container width and has our standard padding
    display: block !important;
    width: auto !important;
    max-width: 576px !important;
    padding: calc($grid-gutter-width / 2) !important;

    @include media-breakpoint-down(xs) {
      left: 0 !important;
      right: 0 !important;
      width: calc(100% - 30px) !important;
      margin: 0 auto !important;
    }
  }

  :global(.react-tooltip-lite-arrow) {
    border-color: $charcoal-grey;
  }
}
