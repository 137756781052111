@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.title {
  font-family: $font-family-base;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: $charcoal-grey;
}

.description {
  font-family: $font-family-base;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.3px;
  color: $charcoal-grey;
}

.notice {
  font-family: $font-family-base;
  font-size: 16px;
  letter-spacing: -0.3px;
  color: $steel-grey;
}

.actions {
  @include flexbox(space-between, center);
}

.cancelBtn {
  flex-grow: 1;
  margin-right: 5px;
}

.yesBtn {
  flex-grow: 1;
  margin-left: 5px;
}
