@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.container {
  padding: calc($grid-gutter-width / 2);
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    padding-top: 40px;
  }
}

.fullWidthContainer {
  max-width: 100%;
  padding: 0;
  margin: 0;

  @include media-breakpoint-up(md) {
    padding-top: 0;
  }
}

.content {
  margin-top: 40px;
}

.cancelButton {
  margin-top: 20px;
  margin-bottom: 40px;
  min-width: 100px;
}
