@import "~nvent-web/style/variables";

$red-box-shadow: 0 3px 12px 0 $lipstick-shadow;
$red-box-shadow-hover: 0 6px 15px 0 $lipstick-shadow;

.button {
  composes: btn from global;
  font-weight: $font-weight-medium-bold;
  border: none;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  margin-left: calc($grid-gutter-width / 4);
}

$buttonWidths: (
  "large": 180px,
  "medium": 140px,
  "small": 125px,
  "extra-small": 100px,
);

@mixin fixedWidth($size) {
  $width: map-get($buttonWidths, $size);

  min-width: $width;
}

// size-specific styles:
.extra-small,
.xs {
  composes: btn-xs from global;

  &.fixedWidth {
    @include fixedWidth(extra-small);
  }
}

.small,
.sm {
  composes: btn-sm from global;

  &.fixedWidth {
    @include fixedWidth(small);
  }
}

.medium,
.md {
  &.fixedWidth {
    @include fixedWidth(medium);
  }
}

.large,
.lg {
  composes: btn-lg from global;

  &.fixedWidth {
    @include fixedWidth(large);
  }
}

// theme-specific styles:
.primaryRed {
  composes: btn-primary-red from global;
  box-shadow: $red-box-shadow;

  &:hover {
    background-color: $faded-red;
    box-shadow: $red-box-shadow-hover;
  }

  &:focus,
  &:active {
    background-color: $rouge;
  }
}

.primaryGreen {
  background-color: $medium-green;
  color: $white;
  font-weight: 500;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: 0 3px 12px 0 rgba(58, 180, 75, 0.54);

  &:hover {
    background-color: $medium-green;
    color: $white;
  }

  &:not(:disabled, .disabled):active,
  &:not(:disabled, .disabled):focus {
    background-color: $medium-green;
    color: $white;
    outline: none;
  }
}

.secondaryRed {
  composes: btn-secondary-red from global;
  display: inline-block;
  color: $lipstick;
  text-align: center;

  &:hover {
    background-color: $very-light-pink;
    color: $darkish-pink;
  }

  &:not(:disabled, .disabled):active,
  &:not(:disabled, .disabled):focus {
    background-color: $soft-pink;
    color: $rouge;
    outline: none;
  }
}

.primaryBlue {
  background-color: $cerulean-blue;
  color: $white;
  font-weight: 500;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: 0 3px 12px 0 rgba(0, 126, 241, 0.54);

  &:hover {
    background-color: $cerulean-blue;
    color: $white;
  }

  &:not(:disabled, .disabled):active,
  &:not(:disabled, .disabled):focus {
    background-color: #0060b9;
    color: $white;
    outline: none;
  }
}

.secondaryBlue {
  background-color: $light-sky-blue-two;
  color: $cerulean-blue;
  font-weight: 500;
  padding-left: 30px;
  padding-right: 30px;

  &:hover {
    background-color: $very-light-blue;
    color: $dodger-blue;
  }

  &:not(:disabled, .disabled):active,
  &:not(:disabled, .disabled):focus {
    background-color: $baby-blue;
    color: $cerulean;
    outline: none;
  }
}

.secondary {
  background-color: $light-sky-blue;
  color: $steel-grey;
  font-weight: 500;
}
