@import "~nvent-web/style/variables";

.input {
  composes: form-control from global;
  border: 0;

  &::placeholder {
    color: $light-blue-grey;
  }
}

.isCorrect {
  border-color: $medium-green !important;
  background: $light-green !important;

  &:focus {
    box-shadow: 0 0 0 2px $medium-green;
  }
}

.isInvalid {
  border-color: $darkish-pink !important;
  background: $light-red !important;

  &:focus {
    box-shadow: 0 0 0 2px $darkish-pink;
  }
}

// TODO: replace the above with composes once composes from .scss is fixed in react-scripts
// This bug was mentioned in this create-react-app issue: https://github.com/facebook/create-react-app/issues/7919
// .input {
//   composes: input from "./AbstractField.module.scss";
// }
// .isCorrect {
//   composes: isCorrect from "./AbstractField.module.scss";
// }
// .isInvalid {
//   composes: isInvalid from "./AbstractField.module.scss";
// }

.predictions {
  background-color: $white;
  position: absolute;
  z-index: 999;
  top: 40px;
  width: 100%;
  max-height: 350px;
  overflow-y: scroll;
}

.prediction {
  cursor: pointer;
  padding: 5px;
}
