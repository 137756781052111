@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.details {
  padding: 0 16px;

  @include media-breakpoint-up(lg) {
    padding: 0;

    @include flexbox(flex-start, center);

    font-weight: 400;
  }
}

.clientName {
  font-family: $font-family-base;
  font-size: 14px;
  color: $charcoal-grey;
  margin-right: 14px;
}

.phoneNumber {
  font-family: $font-family-base;
  font-size: 14px;
  font-weight: 500;
  color: $cerulean-blue;
  margin-right: 10px;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  @include media-breakpoint-up(lg) {
    color: $charcoal-grey;
  }
}

.startDate {
  font-family: $font-family-base;
  font-size: 14px;
  color: $nobel-gray;
  margin: 10px 0;

  @include media-breakpoint-up(lg) {
    margin: 0 15px 0 0;
    color: #a6a6a6;
  }
}

.cardHeader {
  padding: 16px;
}
