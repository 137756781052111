@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.addressDetails {
  composes: row from global;

  @include media-breakpoint-up(lg) {
    display: block;
    margin: 0;
  }
}

.shortField {
  composes: col-6 col-lg-12 from global;

  @include media-breakpoint-up(lg) {
    padding-left: 0;
    padding-right: 0;
  }
}

.inputLeft {
  composes: shortField;
}

.inputRight {
  composes: shortField;
}

.title {
  font-family: $font-family-base;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: $charcoal-grey;
  composes: col-12 from global;

  @include media-breakpoint-up(lg) {
    padding-top: 14px;
    font-size: 22px;
  }
}

.separator {
  display: block;
  right: 0;
  margin: 0.5em auto;
  border: none;
  color: $light-sky-blue;
  background-color: $light-sky-blue;
  height: 2px;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.notice {
  color: $steel-grey;
}

.actions {
  @include flexbox(space-between, center);

  @include media-breakpoint-up(lg) {
    padding-top: 28px;
  }
}

.form {
  composes: row from global;
}

.column {
  composes: col-lg-6 from global;
}
