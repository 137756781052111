@import "~nvent-web/style/variables";
@import "~nvent-web/style/mixins";

.logIn {
  margin-top: 30px;
  margin-bottom: calc($grid-gutter-width / 2);

  @include flexbox(center, center);

  flex-direction: column;
}

.logInButton {
  border: none;
  margin-top: 1rem;
}
