@import "~nvent-web/style/variables";

.checkboxContainer {
  composes: custom-control custom-checkbox from global;
}

.radioContainer {
  composes: custom-control custom-radio from global;
}

.input {
  composes: custom-control-input from global;
}

.label {
  composes: custom-control-label from global;
  font-size: 14px;
  color: $charcoal-grey;
}

.wrapper {
  composes: form-group from global;
}

.prompt {
  margin: 0;
  color: $light-gray;
  font-size: 11px;
}

// TODO: replace the above with composes once composes from .scss is fixed in react-scripts
// This bug was mentioned in this create-react-app issue: https://github.com/facebook/create-react-app/issues/7919
// .wrapper {
//   composes: wrapper from "./AbstractField.module.scss";
// }
// .prompt {
//   composes: prompt from "./AbstractField.module.scss";
// }
