@import "~nvent-web/style/variables";

.wrapper {
  padding-top: 20px;
}

.text {
  font-size: 18px;
  color: $steel-grey;
  text-align: center;
}

.button {
  margin: 0 auto;
}
