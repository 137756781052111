@import "~nvent-web/style/variables";

.wrapper {
  margin-bottom: 20px;
}

.header {
  margin: 10px 0;
}

.image {
  display: inline-block;
  width: auto;
  height: 300px;
  margin-bottom: 20px;
}

.link {
  color: $black;

  &:hover {
    color: $dark-gray;
  }
}

.content {
  white-space: pre-wrap;
}
